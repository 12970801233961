.services_information {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	padding: 35px 35px;
}

.row_services {
	width: 100%;
}
.row_services_img {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 25px 0;
	padding-bottom: 45px;
}
.wapperimg img {
	cursor: pointer;
}
/* styles valores */
.services_valores {
	width: 100%;
}
.services_valores-content {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.valores_title {
	text-align: center;
	font-size: 40px;
	font-weight: 700;
	padding-bottom: 26px;
	padding-top: 18px;
	color: #ffff00;
}
.services_valores-content .valores {
	font-weight: 600;
	padding: 15px 20px;
	font-size: 20px;
	margin: 5px 5px;
	border: 2px solid #0050a9;
	border-radius: 7px;
	color: #33475c;
	cursor: pointer;
	transition: 0.2s;
	cursor: pointer;
}
.services_valores-content .valores:hover {
	background: #0050a9;
	border: 2px solid #0050a9;
	border-radius: 7px;
	color: white;
	cursor: pointer;
}
/* styles valores */

.home_clients {
	margin: 0 auto;
	max-width: 1200px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.home_clients img {
	width: 100%;
}

.img_home_clients {
	width: 320px;
}

.title_clients {
	text-align: center;
	font-size: 35px;
	font-weight: 700;
	padding-top: 33px;
	padding-bottom: 0px;
	color: #173a72;
	text-decoration: underline;
}

@media (max-width: 960px) {
	.services_valores-content .valores {
		font-size: 18px;
	}
}
@media (max-width: 768px) {
	.services_valores-content .valores {
		font-size: 16px;
	}
}
@media (max-width: 768px) {
	.services_valores-content .valores {
		width: 100%;
		text-align: center;
	}
	.img_home_clients {
		width: 50%;
	}
	.title_clients {
		font-size: 30px;
	}
}
@media (max-width: 567px) {
	.services_valores-content .valores {
		width: 100%;
		text-align: center;
	}
	.img_home_clients {
		width: 50%;
	}
	.title_clients {
		font-size: 24px;
	}
}
