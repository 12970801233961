.row_works {
	width: 100%;
	cursor: pointer;
}
.wapperimg {
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}
.wapperimg:hover img {
	transform: scale(1.1);
	transition: all 0.3s ease-in-out;
}
