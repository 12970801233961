.content_home {
	height: 100%;
	display: flex;
	/* position: absolute; */
	width: 100%;
	flex-direction: column;
	position: relative;
}

.animate__animated.animate__delay-1se {
	animation-delay: 0.8s;
}
.animate__animated.animate__delay-2se {
	animation-delay: 1.6s;
}
.animate__animated.animate__delay-3se {
	animation-delay: 2.4s;
}
.animate__animated.animate__delay-4se {
	animation-delay: 3.2s;
}
.animate__animated.animate__delay-5se {
	animation-delay: 4s;
}
.animate__animated.animate__delay-6se {
	animation-delay: 4.8s;
}
.animate__animated.animate__delay-7se {
	animation-delay: 5.6s;
}

.Home_container {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.overlay {
	width: 100%;
	background: #000000a8;
	display: flex;
	bottom: 0;
	align-items: center;
	color: white;
	font-size: 22px;
	font-family: 'Montserrat', sans-serif;
}

.title_overlay_two {
	font-size: 42px;
	color: yellow;
	padding-bottom: 25px;
	font-weight: 600;
	text-align: center;
	padding-top: 15px;
}
.info_slider_two {
	text-align: center;
	font-size: 32px;
	padding-bottom: 5px;
}

.img_content {
	height: 100%;
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.button_header.two {
	text-align: center;
	padding-top: 20px;
}

.overlay {
	height: 100vh;
	padding-top: 100px;
	padding-bottom: 25px;
}

.swiper_content_app {
	width: 100%;
	margin: 0 auto;
}
.slick-next {
	right: 0px !important;
}

.slick-prev {
	left: 0px !important;
	z-index: 25;
}
.sliderReact_1 {
	height: 100%;
	width: 100vh;
	background: url('../../images/cover1.jpg') center center;
	background-size: cover;
}
.sliderReact_2 {
	background: url('../../images/cover2.jpg') center center;
	background-size: cover;
}
.sliderReact_3 {
	background: url('../../images/cover3.jpg') center center;
	background-size: cover;
}

.title_overlay {
	font-size: 50px;
	color: yellow;
	padding-bottom: 25px;
	font-weight: 500;
	padding-top: 15px;
}

.button_header button {
	cursor: pointer;
}
.button_header button {
	padding: 12px 22px;
	border: 0;
	background: yellow;
	color: black;
	border: 1px solid black;
	font-size: 27px;
}

.content_li {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.info_slider_two {
	color: yellow;
	text-align: center;
	font-size: 25px;
	border: 1px solid white;
	border-radius: 10px;
	padding: 12px 19px;
	margin: 9px;
	background: transparent;
}
/* project style */

.slick-dots {
	transform: translateY(-24px);
}

/*end project style */

/* querys */

@media (max-width: 1440px) {
	.title_overlay_two {
		font-size: 32px;
	}
	.info_slider_two {
		font-size: 18px;
	}
	.button_header button {
		font-size: 24px;
	}
}

@media (max-width: 1140px) {
	.overlay .img_content {
		padding-right: 25px;
		padding-left: 25px;
	}
	.project_count {
		flex-wrap: wrap;
	}
	.project_box {
		padding-bottom: 25px;
	}
	.title_overlay_two {
		font-size: 26px;
	}
}

@media (max-width: 900px) {
	.logo img {
		width: 152px;
		padding-left: 11px;
	}
	.navevent a {
		font-size: 16px;
	}
	.full {
		margin-top: 90px;
		height: 100%;
	}
}

@media (max-width: 768px) {
	.title_overlay {
		font-size: 35px;
	}
	.overlay {
		height: 700px;
	}

	.info_slider_two {
		width: 100%;
		font-size: 20px;
	}
	.overlay .img_content {
		padding-right: 30px;
		padding-left: 30px;
	}

	.title_type {
		text-align: center;
	}
	.title_overlay {
		text-align: center;
	}
	.button_header {
		text-align: center;
	}
}

@media (max-width: 620px) {
	.overlay {
		height: 720px;
	}
}
@media (max-width: 576px) {
	.overlay {
		height: 520px;
	}
	.overlay .img_content {
		padding-right: 30px;
		padding-left: 30px;
	}
	.info_slider_two {
		width: 100%;
		font-size: 14px;
		padding: 6px 2px;
		margin: 5px;
	}
	.title_overlay_two {
		font-size: 18px;
	}

	.project_img_content {
		font-size: 14px;
	}
	.project_button button {
		font-size: 16px;
	}
	.project_title {
		font-size: 22px;
	}

	.title_overlay {
		font-size: 25px;
	}
	.button_header button {
		padding: 12px 22px;
		font-size: 16px;
	}
}

/* end querys */
