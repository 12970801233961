.content_about {
	width: 100%;
	margin: 0 auto;
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
}

.about-header {
	background: url('../../images/about-cover.jpg') center center;
	height: 400px;
	background-size: cover;
	color: white;
	padding-top: 122.06px;
}

.about_container {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	font-weight: 500;
}
.about_swiper {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 45px;
}

.about_slider-content img {
	width: 100%;
	cursor: pointer;
}

.about_title {
	font-size: 50px;
	padding-bottom: 20px;
	color: #ffff00;
	text-align: center;
}
.about_info {
	font-size: 20px;
}

.about_overlay {
	background: #000000a6;
	height: 100%;
}

.contact_link {
	border: 1px solid #ffff00;
	background: #ffff00;
	color: #39373d;
	padding: 9px 27px;
	font-size: 24px;
}

.about_information {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	padding: 35px 35px;
}

.row_about {
	width: 50%;
}

.row_about p {
	line-height: 28px;
}
.second_title {
	font-size: 28px;
	font-weight: 600;
	color: #ffff00;
	margin: 35px 0 12px 0;
}

.img_about img {
	width: 100%;
	padding: 0 33px;
}

.link_contact {
	cursor: pointer;
	text-align: center;
}
.link_contact button {
	cursor: pointer;
}

@media (max-width: 1200px) {
	.about_container {
		padding: 0 23px;
	}
}
@media (min-width: 768px) {
	.about_swiper.mobile {
		display: none;
	}
}
@media (max-width: 768px) {
	.about-header {
		padding-top: 90px;
	}
	.about_information {
		flex-direction: column-reverse;
	}
	.row_about {
		width: 100%;
	}
	.row_about img {
		padding-bottom: 15px;
	}
	.row_about p {
		line-height: 22px;
	}
	.about-header {
		height: 270px;
	}
	.about_title {
		font-size: 40px;
		padding-bottom: 17px;
	}
	.contact_link {
		font-size: 21px;
		margin-top: 4px;
	}
	.img_about img {
		width: 100%;
		padding: 0;
		object-fit: cover;
		height: 303px;
		object-position: top;
	}
	.about_swiper {
		padding-bottom: 24px;
		padding-top: 12px;
	}
	.about_swiper.desktop {
		display: none;
	}
}
@media (max-width: 480px) {
	.img_about img {
		height: initial;
	}
}
@media (max-width: 480px) {
	.about_title {
		font-size: 28px;
	}
	.about_info {
		font-size: 14px;
	}
	.contact_link {
		font-size: 15px;
	}

	.about-header {
		height: 209px;
	}
}
