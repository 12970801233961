.footer_container {
	width: 100%;
	background-color: #efefef;
	font-family: 'Raleway', sans-serif;
}

.footer-content {
	max-width: 1200px;
	margin: 0 auto;
}
.footer-content-box {
	display: flex;
	padding-top: 22px;
}
.footer-box-item {
	width: 33.333%;
	padding: 15px 10px;
	padding-top: 25px;
}
.img-logo-footer img {
	width: 170px;
}

.footer-box-item p {
	padding-bottom: 15px;
}

.footer-content-box .footer-content-box-item:nth-child(2) {
	width: 33.33%;
	text-align: center;
	border-left: 1px solid black;
	border-right: 1px solid black;
	font-size: 21px;
	font-weight: 600;
}
.footer-content-box .footer-content-box-item:nth-child(2) p:nth-child(1) {
	text-decoration: none;
	padding-bottom: 25px;
	color: #616161;
}
.footer-content-box .footer-content-box-item:nth-child(2) p:nth-child(1) a {
	text-decoration: none;
	color: #616161;
}
.title_redes {
	padding-bottom: 15px;
}
.footer-redes a {
	text-decoration: none;
	padding: 0 10px;
}
.footer-redes a div {
	background: #4e4e4e;
	width: 50px;
	height: 50px;
	display: flex;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	color: white;
}
.content_redes {
	display: flex;
	justify-content: center;
}

.footer_pay {
	font-size: 43px;
	color: #4e4e4e;
	padding-top: 15px;
}
.footer_pay span {
	padding-right: 15px;
}

.footer-content-box .footer-content-box-item:nth-child(3) p {
	font-size: 35px;
	font-weight: 600;
	padding-bottom: 2px;
}
.footer-content-box .footer-content-box-item:nth-child(3) {
	padding: 0 25px;
}

@media only screen and (max-width: 1200px) {
	.footer-content {
		padding: 0 20px;
	}
}

@media screen and (max-width: 1100px) {
	.footer-content-box .footer-content-box-item:nth-child(3) p {
		font-size: 24px;
		font-weight: 600;
		padding-bottom: 2px;
	}
}

@media only screen and (max-width: 960px) {
	.footer-content-box {
		display: block;
		padding-top: 22px;
		text-align: center;
	}
	.footer-box-item {
		width: 100%;
	}
	.footer-content-box .footer-content-box-item:nth-child(2) {
		margin: 0 auto;
		border-left: none;
		border-right: none;
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		padding: 20px 0;
	}
	.footer-content-box .footer-content-box-item:nth-child(3) p {
		padding-top: 15px;
	}
}
@media only screen and (min-width: 576px) {
	.derechos.mobile {
		display: none;
	}
}
@media only screen and (max-width: 576px) {
	.content_info {
		display: initial;
		text-align: center;
	}
	.info,
	.redes,
	.explorer {
		padding-bottom: 12px;
	}
	.content_info .title-info {
		font-weight: 600;
		padding-bottom: 4px;
	}
	.derechos.desktop {
		display: none;
	}

	.diseño {
		padding-top: 10px;
	}
}
