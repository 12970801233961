:root {
	--primary: #ffff00;
}
.btn {
	padding: 8px 20px;
	border-radius: 4px;
	outline: none;
	border: none;
	font-size: 18px;
	color: black;
	border: 2px solid var(--primary);
	cursor: pointer;
	background-color: var(--primary);
}

.btn:hover {
	padding: 8px 20px;
	transition: all 0.3s ease-out;
	background-color: transparent;
	color: #fff;
	border-radius: 4px;
	border: 2px solid var(--primary);
	transition: all 0.3s ease-out;
}
