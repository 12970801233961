.machine_information {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	padding: 35px 35px;
	flex-direction: column;
}
.row_machine {
	width: 100%;
}
.machine_info {
	font-size: 20px;
	line-height: 30px;
	padding-bottom: 45px;
}

@media (max-width: 960px) {
	.machine_info {
		font-size: 18px;
		line-height: 30px;
	}
}
@media (max-width: 768px) {
	.machine_info {
		font-size: 16px;
		line-height: 24px;
	}
}
