.navbar {
	/* 	background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	font-family: 'Montserrat', sans-serif;
	z-index: 99;
	position: fixed;
	width: 100%;
	background: #000000e3;
}

.navbar__content {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	width: 100%;
	justify-content: space-between;
	z-index: 98;
}

.navbar__content a {
	padding: 8px 10px;
	border: 2px solid transparent;
}

.navbar__content a.current {
	padding: 8px 10px;
	transition: all 0.3s ease-out;
	background-color: transparent;
	color: #fff;
	border-radius: 4px;
	border: 2px solid #acac33;
}

.navbar__content .nav-item:nth-child(5) a {
	border: 2px solid yellow;
	border-radius: 4px;
}

.navbar-logo {
	cursor: pointer;
	padding: 10px 0;
	padding-top: 15px;
}
.navbar-logo img {
	width: 220px;
}

.fa-firstdraft {
	margin-left: 0.5rem;
	font-size: 1.6rem;
}

.nav-menu {
	display: flex;
	list-style: none;
	text-align: center;
	align-items: center;
}

.nav-item {
	display: flex;
	align-items: center;
	color: white;
	cursor: pointer;
	height: 100%;
	margin: 0 5px;
}
.nav-item span {
	padding: 0 10px;
}

.nav-menu .nav-item:nth-child(2) {
	/* 	padding: 0 7px; */
}
/* .nav-menu .nav-item:nth-child(2):hover {
	background: #3c3c12;
} */

.nav-links {
	color: white;
	text-decoration: none;
	padding: 0.5rem 1rem;
}

.nav-links:hover {
	background-color: yellow;
	border-radius: 4px;
	transition: all 0.2s ease-out;
	color: black;
}

.fa-bars {
	color: #fff;
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}
.fas.fa-caret-down {
	margin-left: 5px;
}

@media screen and (min-width: 960px) {
	.nav-item.serviceMobile {
		display: none;
	}
}
@media screen and (max-width: 960px) {
	.navbar {
		background: black;
	}

	.nav-item.serviceMobile {
		flex-direction: column;
		height: auto;
	}
	.nav-item.service {
		display: none;
	}
	.NavbarItems {
		position: relative;
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 90vh;
		position: absolute;
		top: 90px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
	}

	.nav-menu.active {
		background: #0e0e0e;
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 10;
	}

	.nav-links {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;
	}

	.nav-links:hover {
		background-color: yellow;
		border-radius: 0;
		color: black;
	}

	.navbar-logo {
		/* 	position: absolute;
		top: 0;
		left: 0;
		transform: translate(25%, 50%); */
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
	}

	.fa-times {
		color: #fff;
		font-size: 2rem;
	}

	.nav-links-mobile {
		display: block;
		text-align: center;
		padding: 1.5rem;
		margin: 2rem auto;
		border-radius: 4px;
		background-color: var(--primary);
		text-decoration: none;
		color: black;
	}

	.nav-links-mobile:hover {
		background: #fff;
		color: #1888ff;
		transition: 250ms;
	}

	.nav-menu button {
		display: none;
	}
}

@media screen and (max-width: 768px) {
	.navbar-logo img {
		width: 150px;
	}
}
